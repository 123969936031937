import React, { useState, useEffect } from "react"
import { withAuthenticator } from "@aws-amplify/ui-react"
import { graphql, navigate } from "gatsby"
import Layout from "../components/layout"
import { setUserSources, getUserSources } from "../graphql/queries"
import { Amplify, API, graphqlOperation } from "aws-amplify"
import awsExports from "../../static/scripts/aws-exports"
import { isLoggedIn } from "../utils/auth"
import Notifications, { notify } from "react-notify-toast"

const SourcesPage = ({ data }) => {
  const [selectedSources, setSelectedSources] = useState({
    data: {
      getUserSources: [],
    },
  })
  const [sourcesList, setSourcesList] = useState(data.nshra.listSources.items)
  const [nameFilter, setNameFilter] = useState("")
  const [countryFilter, setCountryFilter] = useState("")

  const saveUserSources = arr => {
    try {
      if (!arr) return
      Amplify.configure(awsExports)
      var operation = graphqlOperation(setUserSources)
      operation.authMode = "AMAZON_COGNITO_USER_POOLS"
      operation.variables = { sources: arr == [] ? null : arr }
      API.graphql(operation)
      setSelectedSources({
        data: {
          getUserSources: arr,
        },
      })
    } catch (e) {
      console.error(e)
    }
  }

  const filterSources = (name, countryCode) => {
    var arr = data.nshra.listSources.items

    var lName = name != "" ? name : nameFilter
    var lCountryCode = countryCode != "" ? countryCode : countryFilter

    if (lName && lName != "") {
      arr = arr.filter(a => a.arabicName.includes(lName))
    }

    if (lCountryCode && lCountryCode != "") {
      arr = arr.filter(a => a.countryCode == lCountryCode)
    }

    setSourcesList(arr)
  }

  useEffect(() => {
    async function fetchUserSources() {
      try {
        if (selectedSources.data.getUserSources.length > 0) return
        //    setIsLoading(true)
        Amplify.configure(awsExports)
        var operation = graphqlOperation(getUserSources)
        operation.authMode = "AMAZON_COGNITO_USER_POOLS"
        const result = await API.graphql(operation)
        setSelectedSources(result)
        //setResult(data)  setIsLoading(false)
      } catch (e) {
        console.error(e)
      }
    }
    if (!isLoggedIn()) navigate("/")
    fetchUserSources()
  })

  return (
    <Layout>
      <div className="wrapper">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div id="sources">
                <div class="section-header">
                  <div class="row">
                    <div class="col-md-2 col-12">
                      <h3>تعديل المصادر</h3>
                    </div>
                    <div class="col-md-8 col-12">
                      <div class="source-filter">
                        <div class="row">
                          <div class="col-lg-4 col-12">
                            <input
                              type="search"
                              placeholder="بحث..."
                              onChange={e => {
                                setNameFilter(e.target.value)
                                filterSources(
                                  e.target.value == "" ? null : e.target.value,
                                  countryFilter
                                )
                              }}
                            />
                          </div>
                          <div class="col-lg-4 col-md-6 col-12">
                            <select
                              onChange={e => {
                                setCountryFilter(e.target.value)
                                filterSources(
                                  nameFilter,
                                  e.target.value == "" ? null : e.target.value
                                )
                              }}
                            >
                              <option value="">جميع الدول</option>
                              {data.nshra.listCountries
                                .sort((a, b) => a.order - b.order)
                                .map(country => {
                                  return (
                                    <option value={country.code}>
                                      {country.arabicName}
                                    </option>
                                  )
                                })}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="sources-list">
                  {sourcesList.map((item, index) => (
                    <label class="source-item">
                      <input
                        type="checkbox"
                        checked={
                          selectedSources.data.getUserSources.indexOf(item.id) >
                          -1
                        }
                        onChange={() => {
                          var i = selectedSources.data.getUserSources.indexOf(
                            item.id
                          )
                          var arr = selectedSources.data.getUserSources
                          if (i > -1) {
                            arr.splice(i, 1)
                            setSelectedSources({
                              data: {
                                getUserSources: arr,
                              },
                            })
                          } else {
                            arr.push(item.id)
                            setSelectedSources({
                              data: {
                                getUserSources: arr,
                              },
                            })
                          }
                          saveUserSources(arr)
                          notify.show("تم الحفظ بنجاح", "success", 1000)
                        }}
                      />

                      <span class="source-item-wrapper">
                        <span class="source-item-flex">
                          <span class="source-logo">
                            <img
                              referrerPolicy="no-referrer"
                              onError={i => {
                                i.target.style.display = "none"
                              }}
                              src={item.profileImageUrl}
                              alt={item.arabicName}
                            />
                          </span>
                          <span class="source-name">{item.arabicName}</span>
                          <span class="source-icon">
                            <i class="fal fa-check"></i>
                          </span>
                        </span>
                      </span>
                    </label>
                  ))}
                </div>
                {/* <div class="sources-pager">
                  <div class="paging clearfix">
                    <span>
                      <i class="fal fa-chevron-right"></i>
                    </span>
                    <span>1</span>
                    <span>2</span>
                    <span class="active">3</span>
                    <span class="disabled">4</span>
                    <span>5</span>
                    <span>
                      <i class="fal fa-chevron-left"></i>
                    </span>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <ToastsContainer store={ToastsStore} /> */}
    </Layout>
  )
}

export const query = graphql`
  query {
    nshra {
      listSources(limit: 350) {
        items {
          arabicDescription
          arabicName
          categoryID
          countryCode
          id
          profileImageUrl
        }
      }
      listCountries {
        arabicName
        code
        order
      }
    }
  }
`

export default SourcesPage
